import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
  FormHelperText,
  Skeleton,
  TableRow,
  TableCell,
} from '@mui/material';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './Analytics.css';
import { InfoOutlined } from '@mui/icons-material';
import ApexChart from './chart';
import { DatePicker } from 'antd';
import { useState } from 'react';
const { RangePicker } = DatePicker;
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ApiEndpoints } from 'src/config';
import axios from '../../utils/axios';
import Page from 'src/components/Page';
import { useForm } from 'react-hook-form';
import ConversationStat from 'src/sections/@dashboard/Analytics/list/ConversationStat';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

export default function Analytics() {
  const defaultValues = {};
  const NewUserSchema = Yup.object().shape({});
  const [botSessionData, setBotSessionData] = useState([]);
  const [dataFlags, setDataFlags] = useState(false);
  const [countStats, setCountStats] = useState([]);
  const [messageStatus, setMessageStatus] = useState([]);
  const [totalConversationData, setTotalConversationData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const currentYear = dayjs().year();
  const dateFormat = 'YYYY-MM-DD';
  const startDate = dayjs(`${currentYear}-01-01`, dateFormat);
  const currentDate = dayjs();
  const [dates, setDates] = useState([startDate, currentDate]);
  // console.log(dayjs(dates[0]))
  const parsedDates = dates?.map((date) => {
    return dayjs(date).toISOString();
  });
  // console.log(parsedDates);
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;
  const formValues = getValues();
  const { enqueueSnackbar } = useSnackbar();

  const getAgentsDepartments = async () => {
    try {
      const response = await axios.get(ApiEndpoints.DEPARTMENTS);
      const departments = response?.data?.data;
      // console.log(departments);
      setDepartments(departments);
    } catch (error) {
      console.error('Failed to fetch departments:', error);
      enqueueSnackbar('Failed to fetch departments!', { variant: 'error' });
    }
  };
  useEffect(() => {
    getAgentsDepartments();
  }, []);

  const getCountStats = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.COUNT_STATS}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.data?.status === 200) {
        setCountStats(response?.data?.data);
        // console.log('count data', countStats);
      }
    } catch (error) {
      console.error('Failed to fetch Count Stats:', error);
      enqueueSnackbar('Failed to fetch Count Stats!', { variant: 'error' });
    }
  };
  const getMessageStatus = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.MESSAGE_STATUS}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.status === 200) {
        setMessageStatus(response?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch Message Status Data:', error);
      enqueueSnackbar('Failed to fetch Message Status Data!', { variant: 'error' });
    }
  };
  const getTotalConversation = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.ANALYTICS_TOTAL}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.data?.status === 200) {
        setTotalConversationData(response?.data);
        // console.log('total data', totalConversationData);
      }
    } catch (error) {
      console.error('Failed to fetch Bot Conversatison:', error);
      enqueueSnackbar('Failed to fetch Bot Conversatison!', { variant: 'error' });
    }
  };
  const getBotsessionData = async (startDate, endDate, departmentId) => {
    try {
      const response = await axios.get(
        `${ApiEndpoints.ANALYTICS_BOT}?start_date=${startDate}&end_date=${endDate}&department=${departmentId}`
      );
      if (response?.data?.status === 200) {
        setBotSessionData(response?.data?.data);
      }
    } catch (error) {
      console.error('Failed to fetch Bot Conversatison:', error);
      enqueueSnackbar('Failed to fetch Bot Conversatison!', { variant: 'error' });
    }
  };

  const handleDepartmentChange = (event) => {
    setValue('department', event.target.value);
    if (event.target.value && parsedDates?.length > 0) {
      getMessageStatus(parsedDates?.[0], parsedDates?.[1], event.target.value);
      getCountStats(parsedDates?.[0], parsedDates?.[1], event.target.value);
      getTotalConversation(parsedDates?.[0], parsedDates?.[1], event.target.value);
      getBotsessionData(parsedDates?.[0], parsedDates?.[1], event.target.value);
      setDataFlags(true);
      // console.log("Dates",dates[0],dates[1])
    }
  };
  const stats = [
    {
      title: 'Open Conversations',
      count: countStats?.open_conversations,
      tooltipText: 'Count of unresolved conversation',
    },
    {
      title: 'Resolved Conversations',
      count: countStats?.resolved_conversations,
      tooltipText: 'Count of resolved conversation',
    },
    {
      title: 'Web Conversations',
      count: countStats?.web_conversations,
      tooltipText: 'Count of Live chat conversation',
    },
    {
      title: 'Total Conversations',
      count: countStats?.total_conversations,
      tooltipText: 'Total Whatsapp conversation',
    },
    {
      title: 'Whatsapp Template Messages',
      count: countStats?.whatsapp_template_messages,
      tooltipText: 'Count of whatsapp template message initiated',
    },
    {
      title: 'Whatsapp Session Messages',
      count: countStats?.whatsapp_session_messages,
      tooltipText: 'Count of whatsapp reply messages within 24 hrs window',
    },
    {
      title: 'Active Conversations',
      count: countStats?.active_conversations,
      tooltipText: 'Open conversation within 24 hours',
    },
    {
      title: 'Whatsapp Business Initiated',
      count: countStats?.whatsapp_business_initiated,
      tooltipText: 'Conversation initiated by the business like utility/marketing',
    },
  ];
  const clearData = () => {
    setValue('department', '');
    setBotSessionData([]);
    setCountStats([]);
    setTotalConversationData([]);
    setMessageStatus([]);
    setDataFlags(false);
  };
  return (
    <div className="analytics_wrapper">
      <Page title="Analytics Dashboard" style={{ marginLeft: '20px', marginRight: '20px' }}>
        <section>
          <div className="analytics-headd">
            <div className="chat-range">
              <RangePicker
                className="date-css"
                defaultValue={[startDate, currentDate]}
                onChange={(values) => {
                  clearData();
                  setDates(
                    values?.map((item) => {
                      return dayjs(item).format('YYYY-MM-DD');
                    })
                  );
                }}
              />
            </div>
            {parsedDates?.length > 0 ? (
              <div style={{ marginTop: '8px' }}>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="department-multi-select-label">Department</InputLabel>
                  <Select
                    sx={{ background: 'white', boxShadow: '1px 2px 3px #ddd' }}
                    size="sm"
                    labelId="department-select-label"
                    id="department-select"
                    name="department"
                    value={formValues?.department}
                    onChange={(e) => handleDepartmentChange(e)}
                    input={<OutlinedInput label="Department" />}
                  >
                    {departments?.map((department) => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors?.department?.message)}>
                    {errors?.department?.message}
                  </FormHelperText>
                </FormControl>
              </div>
            ) : (
              <> </>
            )}
          </div>
        </section>
        {dataFlags && parsedDates?.length > 0 ? (
          <>
            <section className="migrate-child">
              {stats.map((stat, index) => (
                <ConversationStat key={index} title={stat.title} count={stat.count} tooltipText={stat.tooltipText} />
              ))}
            </section>
            <section className="second-section">
              <div className="parent-1">
                <div className="box-3">
                  <div className="inside-box">
                    <div className="inside-pad">
                      <div className="css-126w66a">
                        <p>
                          WhatsApp Template Messages Vs Session Messages{' '}
                          <Tooltip
                            title="day wise WhatsApp Template Messages Vs Session Messages"
                            arrow
                            placement="top"
                          >
                            <InfoOutlined fontSize="10px" />{' '}
                          </Tooltip>
                        </p>
                      </div>
                    </div>
                    <ApexChart isWaBar={true} />
                  </div>
                </div>
              </div>
              <div className="parent-2">
                <div className="box-3">
                  <div className="inside-box">
                    <div className="inside-pad">
                      <div className="css-126w66a">
                        <p>
                          Whatsapp message status{' '}
                          <Tooltip title="whatsapp outgoing messages by different statuses" arrow placement="top">
                            <InfoOutlined fontSize="10px" />{' '}
                          </Tooltip>
                        </p>
                      </div>
                    </div>
                    <ApexChart isBar={true} chartdata={messageStatus} />
                  </div>
                </div>
              </div>
            </section>
            <Box sx={{ flexGrow: 1 }}>
              <section className="third-one">
                <div className="parent-4">
                  <div className="box-3">
                    <div className="inside-box">
                      <div className="inside-pad">
                        <div className="css-126w66a">
                          <p>
                            Conversations{' '}
                            <Tooltip title="Day wise total number of conversation created" arrow placement="top">
                              <InfoOutlined fontSize="10px" />
                            </Tooltip>
                          </p>
                        </div>
                      </div>
                      <ApexChart isLine={true} chartdata={totalConversationData} />
                    </div>
                  </div>
                </div>
              </section>
              <section className="fourth-one">
                <div className="parent-4">
                  <div className="box-3">
                    <div className="inside-box">
                      <div className="inside-pad">
                        <div className="css-126w66a">
                          <p>
                            Bot Sessions{' '}
                            <Tooltip title="day wise Count of conversation handeld by bot" arrow placement="top">
                              <InfoOutlined fontSize="10px" />
                            </Tooltip>
                          </p>
                        </div>
                      </div>
                      <ApexChart isBot={true} chartdata={botSessionData} />
                    </div>
                  </div>
                </div>
              </section>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Page>
    </div>
  );
}
